<script setup lang="ts">
import AuthenticatedNavbar from "@/Layouts/AuthenticatedNavbar.vue"
import Footer from "@/Layouts/Footer.vue"
import GuestNavbar from "@/Layouts/GuestNavbar.vue"
import Toast from "primevue/toast"
import { usePage } from "@inertiajs/vue3"
const { auth } = usePage<SharedProps>().props
</script>

<template>
	<div>
		<Toast />
		<GuestNavbar v-if="auth?.user == null" />
		<AuthenticatedNavbar v-else />
		<div class="mt-[4.68rem]"><slot /></div>
		<Footer />
	</div>
</template>
